.card.resource_card .card-img-certi-top {
    height: 11rem !important;
}

.author_cert_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.fixed_content_cert {
    /* height: 50px; */
    display: -webkit-box !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}
.f-15 {
    font-size: 15px !important;
}
.f-12 {
    font-size: 12px !important;
    text-align: left;
}
.star_cert{
    font-size: 20px;
    color: #004AAD ;
}

.fixed_content {
  /* height: 50px; */
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
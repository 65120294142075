/* blog style */
.user .user-img {
  width: 150px;
    border: 10px solid #0d6efd;
    border-radius: 50%;
  }
  
  .user-img img {
    width: 100%;
    border-radius: 50%;
  }
  
  .user {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .user .user-detail {
    background: #bdbdbd;
    border-radius: 50%;
    padding: 12px;
    position: absolute;
    bottom: -15px;
    right: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
}
  
  .user-detail p {
    margin-bottom: 0;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
  }

  .rightblog {
    padding-left: 20px;
}
  
  .user-ai h3 {
    font-size: 21px;
    font-weight: 600;
    color: #6c6c6c;
    margin-bottom: 8px !important;
}
  
.user-ai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-bottom: 25px;
}

.hm_news_wrapper {
    padding: 50px 0px;
}
  
  .user-ai p {
    margin-top: 0;
    color: #acabab;
    margin-bottom: 6px !important;
    font-weight: 400;
    line-height: 23px;
}
  
  .user-ai a {
    color:#004AAD;
    text-decoration: none;
    font-weight: 600;
  }

  .leftblog.position-relative {
    width: 290px;
}
  
  .container .underline {
    position: relative;
    z-index: 1;
  }
  
  .container .underline::after {
    content: "";
    height: 1px;
    width: 90%;
    position: absolute;
    background: #b7b5b5;
    z-index: -2;
    bottom: 6px;
    right: 0;
  }
  /* blog style end */

  /* view more */
  .hm_news_wrapper .view-more a{
    padding: 10px 25px 10px 25px;
    display: inline-block;
    color: white;
    border-radius: 60px !important;
}
  .hm_news_wrapper .view-more .btn1{
    background: #004AAD;
}
.hm_news_wrapper .view-more{
   text-align: right;
   position: relative;
}
.hm_news_wrapper .view-more .btn1::before{
    content: "";
    border-bottom: 1px solid #333;
    position: absolute;
    top: 24px;
    /* bottom: 0px; */
    margin: 0 auto;
    z-index: 99;
    left: 0px;
    right: 140px;
}

/* Responsive */
@media only screen and (max-width: 767px) {
.hm_news_wrapper .contents  h1{
  font-weight: 600;
  font-size: 32px;
}
  
}

.text-grey{
color:  #666!important  ;
}
.modal-content{
    border-radius: 0.125rem;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    border: 0 solid rgba(0,0,0,.5)!important;
   
}

@media (min-width: 576px){
.modal-sm {
    max-width: 400px  !important;
}}
/* @media (min-width: 320px){
    .modal-sm {
        max-width: 300px  !important;
    }} */


.text-danger{
    color: #ff3547  !important;
  
}
/* .active{

        border: 1px solid #fff !important;
        display: inline-block !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
    } */
.modal-body {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
    }
    .modal-body {
        padding:1.5rem !important;
    }
    .modelbtn button ,.btn-model{
     background-color: #004AAD;
     color: #fff;
     border-radius:40px !important;
     padding: 4px 27px !important;

    }
    .modelbtn button:hover,.btn-model:hover {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc;
    }
    .waves-effect {
        position: relative;
        overflow: hidden;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
    .modelbtn button:focus ,.btn-model:focus{ 
        outline: 0 !important;
        box-shadow: none !important;

    }
     .contact_success_width {
        width: 400px !important;
    }
    .text-model{
        color: #004AAD !important;
        font-weight: 300 !important;
        margin-top: 18px;
    }
    .text-model> p{
        color: #212529;
    }
   .modelcontent{
       height: 100%;
       display: grid;
       align-items: center;
   }
   .modal.show .modal-dialog {
    transform: translate(0,0);
    display: grid;
    align-items: center;
    height: 80%;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.noteheading {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 600;

}

.cardadj {
    width: 18rem !important;

    position: relative;
    top: 0;

    transition: top ease 0.5s;
    border: 10px;

}

.cardadj:hover {
    top: -30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-adjustment {
    margin-top: 100px;
}

.cardimg {
    width: 250px !important;
    height: 120px !important;
}

.card-title:hover {
    color: #004AAD !important;
}

.card-space {
    padding-top: 20% !important;
}

.card-space1 {
    padding-top: 13% !important;
}

.card-space2 {
    padding-top: 15% !important;
}

.card-space3 {
    padding-top: 32% !important;
}

.card-space4 {
    padding-top: 24% !important;
}

.card-space5 {
    padding-top: 14% !important;
}

.card-space6 {
    padding-top: 14% !important;
}

.card-space7 {
    padding-top: 22% !important;
}

.card-space9 {
    padding-top: 23% !important;
}

.card-space10 {
    padding-top: 15% !important;
}

.card-space11 {
    padding-top: 30% !important;
}

.card-space12 {
    padding-top: 22% !important;
}

.card-space13 {
    padding-top: 14% !important;
}

.card-space14 {
    padding-top: 14% !important;
}

.card-space15 {
    padding-top: 30% !important;
}

/* news-details */
.resource_detail_page_title {
    line-height: 1.3 !important;
}

.style_summernote p {
    margin-bottom: 10px !important;
    line-height: 1.7rem;
}

.blog_detail {
    margin-top: 0%;
}

.blog_detail_container {
    margin-bottom: 15rem !important;
}

@media (min-width: 320px) and (max-width: 767px) {

    .Cat_rows {
        padding: 0px 0px !important;
        margin-left: -16px !important;
    }
    .market_Subcat_head_Row {
        padding: 9px 0px !important;
        margin-left: 8px !important;
    }
    .market_Division_head_Row{
        padding: 9px 0px !important;
        margin-left: -20px !important;
        margin-right: -45px;
    }
    .subcat_p_0 {
        padding: 2px!important;
    }

    .cardimg {
        width: 320px !important;
        height: 120px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }

    .card-space2 {
        padding-top: 0% !important;
    }

    .card-space3 {
        padding-top: 0% !important;
    }

    .card-space4 {
        padding-top: 0% !important;
    }

    .card-space5 {
        padding-top: 0% !important;
    }

    .card-space6 {
        padding-top: 0% !important;
    }

    .card-space7 {
        padding-top: 0% !important;
    }

    .card-space8 {
        padding-top: 0% !important;
    }

    .card-space9 {
        padding-top: 0% !important;
    }

    .card-space10 {
        padding-top: 0% !important;
    }

    .card-space11 {
        padding-top: 0% !important;
    }

    .card-space12 {
        padding-top: 0% !important;
    }

    .card-space13 {
        padding-top: 0% !important;
    }

    .card-space14 {
        padding-top: 0% !important;
    }

    .card-space15 {
        padding-top: 0% !important;
    }

    .recetleft {
        left: -2% !important;
        margin: 2% !important;
    }

    .media-body p {
        font-size: 16px !important;
        font-weight: 600 !important;

    }

    .blog_detail_container {
        margin-bottom: 0% !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .cardimg {
        width: 200px !important;
        height: 80px !important;
    }

    .cardadj .card-title {
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }

    .card-space2 {
        padding-top: 14% !important;
    }

    .card-space3 {
        padding-top: 14% !important;
    }

    .card-space4 {
        padding-top: 14% !important;
    }

    .card-space5 {
        padding-top: 8% !important;
    }

    .card-space6 {
        padding-top: 8% !important;
    }

    .card-space7 {
        padding-top: 14% !important;
    }

    .card-space8 {
        padding-top: 5% !important;
    }

    .card-spacehap {
        padding-top: 14% !important;
    }

    .card-space11 {
        padding-top: 20% !important;
    }

    .card-space10 {
        padding-top: 20% !important;
    }

    .card-space13 {
        padding-top: 21% !important;
    }

    .recetleft {
        left: 0%;
    }

    .media-body p {
        font-size: 10px !important;
        font-weight: 400;
    }

    .resource_detail_page_title {
        font-size: 1rem !important;
        line-height: 2rem !important;
        font-weight: 600 !important;

    }

    .style_summernote p {
        margin-bottom: 10px !important;
        line-height: 1.5rem !important;
        font-size: 12px !important;
    }

    .blog_detail_container {
        margin-bottom: 0% !important;
    }
}

@media only screen and (width: 540px) {
    .cardimg {
        width: 500px !important;
        height: 210px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .cardadj {
        width: auto !important;
    }

    .cardimg {
        width: 200px !important;
        height: 140px !important;
    }

    .cardadj .card-title {
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .card-space1 {
        padding-top: 20% !important;
    }

    .card-space3 {
        padding-top: 22% !important;
    }

    .card-space5 {
        padding-top: 8% !important;
    }

    .card-space6 {
        padding-top: 8% !important;
    }

    .card-space7 {
        padding-top: 14% !important;
    }

    .card-spacelocus {
        padding-top: 14% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .blog_detail_container {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

@media (min-width: 1024px) {
    .ec-sidebarWrap {
        float: right;
        position: relative;
    }

    .ec-sidebar {
        position: absolute;
    }

}

@media (min-width: 1025px) and (max-width: 1640px) {
    .blog_detail_container {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.star-rating {
    font-size: 50px;
}

.star-rating button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.on {
    color: gold;
}

.off {
    color: #ccc;
}

.carousel-inner img {
    width: 800px;
    height: 420px;
    object-fit: contain;
}

.eventslide img {
    width: 800px;
    height: 420px;
    object-fit: cover !important;
}

.side_img_div {
    height: 420px;
}


#custCarousel .carousel-indicators {
    position: relative;
    margin-top: 12px;
    display: block;
    margin-right: -15px;
}

#custCarousel .carousel-inner {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px;
}

#custCarousel .carousel-indicators>li {
    width: 100px;
    height: 85px;
}

#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.5;
    padding: 2px;
    width: 100%;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, .125);
}

#custCarousel .carousel-indicators li.active img {
    opacity: 1;
    padding: 2px;
    border: 1.5px solid #004AAD;
}

#custCarousel .carousel-indicators li:hover img {
    opacity: 0.75
}

.carousel-item img {
    width: 100%
}

.side_img_div {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.side_img_div::-webkit-scrollbar {
    display: none;
}

.privacy_content p,
.privacy_content ul li {
    font-size: 20px;
    line-height: 35px;
    font-weight: 300;
}

.privacy_content h3 {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 300;
    padding-bottom: 7px;
}

.sticky-filter-course {
    position: sticky;
    top: 90px;
    overflow-y: scroll;
    overflow: hidden;
}

.carousel_text {
    text-align: start;
}

.event_carousel {
    width: 85%;
    margin: 0 auto;
}

.bakground_color {
    background-color: white;
}

.register_btn {
    padding: 1em;
    border: 1px solid #004AAD;
    width: 45%;
    text-align: center;
    color: white;
    background: #004AAD;
}

.register_btn:hover {
    border: 1px solid #004AAD;
    background-color: #004AAD !important;
    color: #fff !important;
    box-shadow: 0 4px 5px rgb(0 0 0 / 15%), 0 5px 5px rgb(0 0 0 / 10%);
}

.register_div {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.register_icon {
    margin: 0 0 0 1.5em;
    font-size: 1.5em;
}

/* mob responsive for navbar */
@media only screen and (max-width: 1024px) {
    .container-fluid {
        width: 97% !important;
    }
}

@media only screen and (max-width: 966px) {
    .container-fluid {
        width: 96% !important;
    }
}

@media only screen and (max-width: 725px) {
    .container-fluid {
        width: 95% !important;
    }
}

@media only screen and (max-width: 580px) {
    .container-fluid {
        width: 94% !important;
    }
}

@media only screen and (max-width: 483px) {
    .container-fluid {
        width: 93% !important;
    }
}

@media only screen and (max-width: 414px) {
    .container-fluid {
        width: 92% !important;
    }
}

@media only screen and (max-width: 362px) {
    .container-fluid {
        width: 91% !important;
    }
}

@media only screen and (max-width: 322px) {
    .container-fluid {
        width: 90% !important;
    }
}

@media only screen and (max-width: 319px) {
    .container-fluid {
        margin-top: 15% !important;
    }
}

/* mob responsive for navbar */

/* Event carousel */
.carousel_image_container {
    margin: 4em 0 0 0;
}

.virtual_div {
    margin: 0em 0 0 0 !important;
}

.upocoming_event_container {
    width: 95.5%;
    margin: 0 auto !important;
}

.carousel_row-mob {
    display: none;
}

@media only screen and (max-width: 1326px) {
    /* .carousel-control-next {
        padding-left: 2em !important;
    } */
}

@media only screen and (max-width: 1150px) {
    /* .carousel-control-next {
        padding-left: 3em !important;
    } */
}

@media only screen and (max-width: 1084px) {
    .date_time_container {
        font-size: 0.9em !important;
    }
}

@media only screen and (max-width: 989px) {
    .date_time_container {
        font-size: 0.8em !important;
    }
}

@media only screen and (max-width: 923px) {
    .date_time_container {
        font-size: 0.7em !important;
    }
}

@media only screen and (max-width: 1008px) {
    /* .carousel-control-next {
        padding-left: 4em !important;
    } */
}

@media only screen and (max-width: 1008px) {
    /* .carousel-control-next {
        padding-left: 5em !important;
    } */
}

@media only screen and (max-width: 1117px) {
    .register_btn {
        width: 65%;
    }
}

@media only screen and (max-width: 880px) {

    /* .slick-slide img {
        display: initial;
    } */
    .img_carousel_mob {
        width: 60% !important;
    }

    .carousel_row {
        display: none;
    }

    .carousel_row-mob {
        display: block;
    }

    .carousel_container {
        max-width: 100% !important;
    }

    /* .carousel-inner {
        height: 615px !important;
    } */

    .carousel-item img {
        width: 60%;
    }

    .carousel-inner img {
        height: 50vh;
        width: 100% !important;
    }

    .register_div {
        flex-direction: column;
    }

    .text_carousel_mob {
        padding: 2.5em;
    }

    .register_btn {
        width: 100%;
    }

    .register_icon {
        margin: 1em 0 0 1.5em;
    }

    .date_mob {
        margin: 0 !important;
    }

    /* .carousel-control-next-icon {
        display: none;
    } */

    .carousel-control-prev-icon {
        display: none;
    }

    .event_carousel {
        width: 100%;
        margin: 0;
    }

    /* .carousel-control-next {
        left: 89% !important;
    } */
}

@media only screen and (max-width:767px) {
    .mob_calander {
        margin: 0.4em 4.5em 0 0 !important;
    }
}

@media only screen and (max-width: 504px) {
    .text_carousel_mob {
        padding: 2.5em;
        /* padding-top: 1em; */
    }

    /* .carousel-inner {
        height: 700px !important;
    } */

    .carousel-item img {
        width: 80%;
    }
}

@media only screen and (max-width: 511px) {
    .img_carousel_mob {
        width: 93% !important;
    }

    .carousel-control-next {
        left: 88% !important;
    }
}

@media only screen and (max-width: 467px) {
    /* .carousel-control-next {
        left: 87% !important;
    } */

    .img_carousel_mob {
        width: 89% !important;
    }
}

@media only screen and (max-width: 441px) {
    /* .carousel-control-next {
        left: 86% !important;
    } */
}

@media only screen and (max-width: 418px) {
    /* .carousel-control-next {
        left: 85% !important;
    } */
}

@media only screen and (max-width: 397px) {
    .carousel_text_heading {
        font-size: 1.5em;
    }

    .f-18 {
        font-size: 15px !important;
    }

    /* .carousel-inner {
        height: 623px !important;
    } */
}

/* mob respnsive for Event carousel */

/* Event carousel */

/* blog details page styles */
.list_style {
    margin-left: 2em;
}

/* blog details page styles */

/* TechtalkEditer */
@media only screen and (max-width: 920px) {
    .direction_column {
        flex-direction: column !important;
    }

    .display_flex {
        display: flex;
    }

    .resource_list_image_mob {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .direction_column {
        flex-direction: row !important;
    }

    .display_flex {
        display: block;
    }

    .resource_list_image_mob {
        width: 50%;
        height: 20vh;
    }

}

/* TechtalkEditer */

/* TechtalkView */
@media only screen and (max-width: 1032px) {
    .note-video-clip {
        width: 96%;
    }
}

/* TechtalkView */

/* Register Modal */
.register_input {
    width: 100%;
    /* margin-top: 1em; */
    border-radius: 0.5em;
    border: 0.3px solid black;
    padding: 0.4em;
    outline: none;
}

.reg_label {
    padding: 1em 0 0 0;
}

.modal_register_btn {
    display: flex;
    padding: 0.5em;
    width: 35%;
    border-radius: 0.5em;
    outline: none;
    border: none;
    background-color: #004AAD;
    justify-content: center;
    align-items: center;
}

/* Register Modal */

.carousel_hole_container {
    background-color: white;
    margin: 5em auto;
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.carousel-control-prev {
    left: 6.5em;
}

.nb-4 {
    margin-bottom: 1.5rem;
}


.carousel-control-next {
    left: 90% !important;
    color: black;
}

@media only screen and (max-width: 880px) {
    .item.active {
        display: block;
        height: 61vh;
    }

    .carousel-control-next {
        display: none;
    }

}

@media only screen and (max-width: 414px) {
    .f-16 {
        font-size: 0.8em !important;
    }

    .mob_calander {
        margin: 0.4em 4.5em 0 0 !important;
        font-size: 0.8em;
    }
}

.visually-hidden {
    display: none;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {

    filter: invert(100%);
    font-size: 36px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon:hover {
    color: #004AAD !important;
}

.register_input:focus,
.register_input:focus:hover {
    border: 2px solid black !important;
    border-radius: 10px !important;
}


.container-fluid::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: 0 0;
}

.editorview:hover {
    cursor: pointer !important;
}

/* TechtalkEditer */

/* TechtalkView */
@media only screen and (max-width: 1032px) {
    .note-video-clip {
        width: 96%;
    }
}

/* TechtalkView */

/* Register Modal */
.register_input {
    width: 100%;
    margin-top: 1em;
    border-radius: 0.5em;
    border: 1px solid black;
    padding: 0.4em;
    outline: none;
}

.modal_register_btn {
    display: flex;
    padding: 0.5em;
    margin: 1em 0 1em 0;
    width: 35%;
    border-radius: 0.5em;
    outline: none;
    border: none;
    background-color: #004AAD;
}

/* .register_input_option{

} */
/* Register Modal */

.carousel_hole_container {
    margin: 5em auto;
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.carousel-control-prev {
    left: 4em;
    color: black;
}

.carousel-control-next {
    left: 87% !important;
    color: black;
}

@media (max-width:990px) {
    .toggle-tabs {
        display: none;
    }
}

.fa-chevron-down:before {

    content: "" !important;
}

.rounded-pill:hover {
    cursor: pointer !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    color: #004AAD;
}

.rounded-pill:focus {
    color: #004AAD !important;
    border: 2px solid #004AAD;
    box-shadow: 0px 1px 1px #00000026;
}

.ec-sidebar {
    position: relative !important;
}


/* Techstyle by barath */

.tech-e {
    align-items: baseline;
    overflow: auto;
    /* height: auto; */
    /* height: 800px; */
    border: 2px green solid;

    /* position:sticky; */
}

#mydiv {
    height: 250px;
    width: 250px;
    overflow: auto;
}

#content {
    height: 800px;
    width: 250px;
    border: 2px green solid;
    background-color: #004AAD;
}


#fixed {
    overflow: auto !important;
}


/* Techtaks mobile responsive */

@media (max-width: 575.98px) {
    .container-fluid {
        width: 100% !important;
        line-height: 1.5;
        -webkit-line-clamp: 2 !important;
    }

    .f-18 {
        font-size: 18px !important;
    }

    .fw-600 {
        font-weight: 600 !important;

    }

    .market h1 {
        margin-top: 10% !important;
        padding: 3rem 0;
    }

    .text-dark {
        font-size: 16px !important;
        color: black !important;
    }

    @media (min-width: 575.98px) and (max-width: 991.98px) {
        .market h1 {
            margin-top: 5% !important;
            padding: 3rem 0;
        }

        .container-fluid {
            width: 100% !important;
            padding: 3rem 0 !important;
        }

        .market h1 {
            margin-top: 10% !important;
        }

    }




}

.list-active:active {
    color: #004AAD !important;
    border: 2px solid #004AAD !important;
    box-shadow: 0px 1px 1px #00000026;
    background-color: unset !important;
}



@media (min-width: 320px) and (max-width: 767px) {
    .market h1 {
        margin-top: 5% !important;
        padding: 3rem 0 !important;
    }

}

@media (min-width: 992px) {
    .market h1 {
        margin-top: 22px !important;
        padding: 3rem 0 !important;
    }

    .selete-w {
        width: 280px !important;
        margin: auto;
    }
}

/* MOBILE RESPONSIVE FOR THE RESOURSEVIEW    BY BARATH */

@media (max-width: 575.98px) {
    .f-16 {
        font-size: 16px !important;
        line-height: 1.5rem;
    }

    .resource_detail_page_title {
        font-size: 24px;
    }

    .broucher_view {
        margin: 0 !important;
    }

    .mt-5 {
        margin: 0% !important;

    }

    .share-mail {
        font-size: 20px !important;
    }

    .resource_detail_footer_nav {
        margin: 0 !important;
        padding: 0 !important;
    }




}

/* blog card */

.blog_date {
    margin: 0 0 0.5em 0 !important;
}

/* blog card */

/*   blog page editor choice mob responsive */

@media (min-width: 881px) and (max-width: 991px) {

    .ed_text {
        font-size: 1em !important;
    }

}

@media (min-width: 576px) and (max-width: 880px) {

    .resource_list_media {
        flex-direction: column !important;

    }

    .media-body {

        margin: 0.5em 0;
    }

    .ed_text {
        font-size: 1em !important;
    }

}


.top_M {
    margin-top: 2.8rem !important;
}

.fr-2 {
    font-size: 24px !important;
    font-weight: 400 !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .media-body p {
        font-weight: 400 !important;
    }
}

#download_brochure:hover {
    background-color: #e0ffe082 !important;
    color: #004AAD !important;
}

@media (min-width:360px) and (max-width:760px) {
    .carousel-inner img {
        height: auto;
        width: 100% !important;
    }

    .Eventdate {
        font-size: 0.8em;
    }

    .mob_calander {
        margin: 0.4em 4.5em 0 1.3em !important;
        font-size: 0.8em;
    }
}

@media (min-width:760px) and (max-width:850px) {
    .carousel-inner img {
        height: 24vh;
        width: 100% !important;
    }

}

.Caro-line-height {
    line-height: 1.5em;
}

.event-headline {
    font-size: 2.8em
}

.event-margin {
    margin-bottom: 0px !important;
}

.resource-width {
    width: 100% !important;
}

.techtalk-lineheight {
    line-height: 1.5em;
}

.Starcount {
    padding-left: 10px;
    font-size: 18px !important;
}

.event-button-wid {
    width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

._center {

    text-align: center;
    margin-top: 10%;
}


.error {
    margin-top: 50px;
}

.font-weight-bold {
    margin: 0 0 8px;
    font-weight: 700;

    font-size: 1.8em;
    line-height: 1.1;
    color: inherit;
}

.lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.4;
}

.error_link {
    color: #337ab7;
    text-decoration: none;
}

.error_img {
    width: 100px;
}

.avator {
    background-color: #004AAD;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

}

.avator>p {
    transform: translate(0px, 25px);
    font-size: 25px;
    color: #fff;
}

.Topscroll {
    bottom: 100px;
    color: #fff !important;
    display: block;
    font-size: 39px;
    height: 43px;
    line-height: 46px;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none !important;
    
    width: 46px;
    z-index: 1000;
    border-radius: 3px;
    background-color: #3d4149;
    z-index: 1 !important;
    cursor: pointer;
}

.Topscroll:hover {
    color: #fff !important;
    height: 43px;
    width: 46px;
    background-color: #004AAD;
    z-index: 1 !important;
    cursor: pointer;
}

.marketmap_main_cat_12{
    margin-left: -8px;
   }
.market_Subcat_head_Row{
    padding: 9px 27px;
   }
.Cat_rows{
    padding: 10px 50px;
    margin-left: -65px;
}
.market_Division_head{
    background-color: #fff;
    padding: 5px 0px 5px 27px;
    margin-left: 15px;
    padding-left: 0px;
}

/* starting breadcrumb */




   
  /* -------------------------------- 
  
  Basic Style
  
  -------------------------------- */
  .cd-breadcrumb, .cd-multi-steps {
    width: 100%;
    padding: 0.5em 1em;
    
    background-color: #edeff0;
    border-radius: .25em;
  }
  .cd-breadcrumb::after, .cd-multi-steps::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    /* this is the separator between items */
    display: inline-block;
    content: '\00bb';
    margin: 0 .6em;
    color: #959fa5;
  }
  .cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
  }
  .cd-breadcrumb li > *, .cd-multi-steps li > * {
    /* single step */
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
  }
  .cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    /* selected step */
    color: #004AAD !important;
  }
  .no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
    /* steps already visited */
    color: #96c03d;
  }
  .cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    /* replace the default separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-separator.svg) no-repeat center center;
    vertical-align: middle;
    margin: 0px 7px;
  }
  .cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
    /* add a custom icon before each item */
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: .4em;
    margin-top: -2px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-icons-01.svg) no-repeat 0 0;
    vertical-align: middle;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
    /* change custom icon using image sprites */
    background-position: -20px 0;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
  }
  .cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
  }
  @media only screen and (min-width: 768px) {
    .cd-breadcrumb, .cd-multi-steps {
      padding: 0 2.4em;
    }
    .cd-breadcrumb li, .cd-multi-steps li {
      margin: 1.2em 0;
    }
    .cd-breadcrumb li::after, .cd-multi-steps li::after {
      margin: 0 1em;
    }
    .cd-breadcrumb li > *, .cd-multi-steps li > * {
      font-size: 16px;
    }
  }
  
.glossary_h1 {
    color: black;
  }
  .glossary_p_text {
    font-size: 17px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .albhabet {
    /* 
    margin-right: 3px; */
    padding-bottom: 6px;
    padding-top: 6px;
    text-align: center;
    color: black !important;
    width: 40px;
    cursor: pointer;
  
  }

  .albhabet span{
    color: black !important;
  }
  .albhabetactive {
    padding-top: 6px;
    text-align: center;
    padding-bottom: 6px;
    margin: 0px 3px;
    color: #ffffff!important;
    background: #004AAD !important;
    width: 40px;
    cursor: pointer;
  }
  .albhabetactive a {
    
    color: #ebebeb!important;
    
  }
  .albhabet:hover{
    background-color: #004AAD !important;
    color: white !important;
  }
  .albhabet:hover a{
    color: #fff!important;
  }
  .alphatext_bg {
    background-color: #edeff0;
    color: #004AAD;
  }
  .div_alp {
    padding-bottom: 20px;
  }
  .div_alp_top{
    padding-bottom: 20px;
    /* padding-top: 150px;  */
  }
  .heading_name {
    color: #004AAD;
  }
  .indi_content {
    font-size: 20px;
    /* opacity: 0.8; */
  }
  .glossary_partcular_btn {
    padding: 7px 5px !important;
    background: #004AAD !important;
    color: white;
    font-size: 14px !important;
    border-radius: 4px !important;
    border: 1px solid #004AAD !important;
  }
  .sticky {
    position: sticky !important;
    top: 80px !important;
    padding: 20px 10px!important;
    z-index: 1020 !important;
    max-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    background-color: white !important;
  }
  
  .scrollspy_example_top{
    padding-top: 300px;
  }
  
  @media only screen   
  and (min-device-width : 320px)   
  and (max-device-width : 600px)   {
    .sticky {
      position: sticky !important;
      top: 55px !important;
     
      padding: 20px 10px!important;

      z-index: 1020 !important;
      max-width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-around !important;
      background-color: white !important;
    } 
    }


    .glossary_alpha_stating_ltr {
        background-color: aqua;
        padding: 10px;
      }
      
      /* .glossary-glossary_number:hover{
      color: #004AAD;
      } */
      span.glossary-test:hover {
        color: #004AAD !important;
        /* text-decoration: underline; */
        border-bottom: 2px solid #004AAD;
      }
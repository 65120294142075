@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.noteheading {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 600;

}

.cardadj {
    width: 18rem !important;

    position: relative;
    top: 0;

    transition: top ease 0.5s;
    border: 10px;

}

.cardadj:hover {
    top: -30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-adjustment {
    margin-top: 100px;
}

.cardimg {
    width: 250px !important;
    height: 120px !important;
}

.card-title:hover {
    color: #004AAD !important;
}

.card-space {
    padding-top: 20% !important;
}

.card-space1 {
    padding-top: 13% !important;
}

.card-space2 {
    padding-top: 15% !important;
}

.card-space3 {
    padding-top: 32% !important;
}

.card-space4 {
    padding-top: 24% !important;
}

.card-space5 {
    padding-top: 14% !important;
}

.card-space6 {
    padding-top: 14% !important;
}

.card-space7 {
    padding-top: 22% !important;
}

.card-space9 {
    padding-top: 23% !important;
}

.card-space10 {
    padding-top: 15% !important;
}

.card-space11 {
    padding-top: 30% !important;
}

.card-space12 {
    padding-top: 22% !important;
}

.card-space13 {
    padding-top: 14% !important;
}

.card-space14 {
    padding-top: 14% !important;
}

.card-space15 {
    padding-top: 30% !important;
}

/* news-details */
.resource_detail_page_title {
    line-height: 1.3 !important;
}

.style_summernote p {
    margin-bottom: 10px !important;
    line-height: 1.7rem;
}

.blog_detail {
    margin-top: 0%;
}

.blog_detail_container {
    margin-bottom: 15rem !important;
}

@media (min-width: 320px) and (max-width: 767px) {

    .Cat_rows {
        padding: 0px 0px !important;
        margin-left: -16px !important;
    }
    .market_Subcat_head_Row {
        padding: 9px 0px !important;
        margin-left: 8px !important;
    }
    .market_Division_head_Row{
        padding: 9px 0px !important;
        margin-left: -20px !important;
        margin-right: -45px;
    }
    .subcat_p_0 {
        padding: 2px!important;
    }

    .cardimg {
        width: 320px !important;
        height: 120px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }

    .card-space2 {
        padding-top: 0% !important;
    }

    .card-space3 {
        padding-top: 0% !important;
    }

    .card-space4 {
        padding-top: 0% !important;
    }

    .card-space5 {
        padding-top: 0% !important;
    }

    .card-space6 {
        padding-top: 0% !important;
    }

    .card-space7 {
        padding-top: 0% !important;
    }

    .card-space8 {
        padding-top: 0% !important;
    }

    .card-space9 {
        padding-top: 0% !important;
    }

    .card-space10 {
        padding-top: 0% !important;
    }

    .card-space11 {
        padding-top: 0% !important;
    }

    .card-space12 {
        padding-top: 0% !important;
    }

    .card-space13 {
        padding-top: 0% !important;
    }

    .card-space14 {
        padding-top: 0% !important;
    }

    .card-space15 {
        padding-top: 0% !important;
    }

    .recetleft {
        left: -2% !important;
        margin: 2% !important;
    }

    .media-body p {
        font-size: 16px !important;
        font-weight: 600 !important;

    }

    .blog_detail_container {
        margin-bottom: 0% !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .cardimg {
        width: 200px !important;
        height: 80px !important;
    }

    .cardadj .card-title {
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }

    .card-space2 {
        padding-top: 14% !important;
    }

    .card-space3 {
        padding-top: 14% !important;
    }

    .card-space4 {
        padding-top: 14% !important;
    }

    .card-space5 {
        padding-top: 8% !important;
    }

    .card-space6 {
        padding-top: 8% !important;
    }

    .card-space7 {
        padding-top: 14% !important;
    }

    .card-space8 {
        padding-top: 5% !important;
    }

    .card-spacehap {
        padding-top: 14% !important;
    }

    .card-space11 {
        padding-top: 20% !important;
    }

    .card-space10 {
        padding-top: 20% !important;
    }

    .card-space13 {
        padding-top: 21% !important;
    }

    .recetleft {
        left: 0%;
    }

    .media-body p {
        font-size: 10px !important;
        font-weight: 400;
    }

    .resource_detail_page_title {
        font-size: 1rem !important;
        line-height: 2rem !important;
        font-weight: 600 !important;

    }

    .style_summernote p {
        margin-bottom: 10px !important;
        line-height: 1.5rem !important;
        font-size: 12px !important;
    }

    .blog_detail_container {
        margin-bottom: 0% !important;
    }
}

@media only screen and (width: 540px) {
    .cardimg {
        width: 500px !important;
        height: 210px !important;
    }

    .cardadj {
        width: auto !important;
    }

    .card-space {
        padding-top: 0% !important;
    }

    .card-space1 {
        padding-top: 0% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .cardadj {
        width: auto !important;
    }

    .cardimg {
        width: 200px !important;
        height: 140px !important;
    }

    .cardadj .card-title {
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .card-space1 {
        padding-top: 20% !important;
    }

    .card-space3 {
        padding-top: 22% !important;
    }

    .card-space5 {
        padding-top: 8% !important;
    }

    .card-space6 {
        padding-top: 8% !important;
    }

    .card-space7 {
        padding-top: 14% !important;
    }

    .card-spacelocus {
        padding-top: 14% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .blog_detail_container {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

@media (min-width: 1024px) {
    .ec-sidebarWrap {
        float: right;
        position: relative;
    }

    .ec-sidebar {
        position: absolute;
    }

}

@media (min-width: 1025px) and (max-width: 1640px) {
    .blog_detail_container {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.star-rating {
    font-size: 50px;
}

.star-rating button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.on {
    color: gold;
}

.off {
    color: #ccc;
}

.carousel-inner img {
    width: 800px;
    height: 420px;
    object-fit: contain;
}

.eventslide img {
    width: 800px;
    height: 420px;
    object-fit: cover !important;
}

.side_img_div {
    height: 420px;
}


#custCarousel .carousel-indicators {
    position: relative;
    margin-top: 12px;
    display: block;
    margin-right: -15px;
}

#custCarousel .carousel-inner {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px;
}

#custCarousel .carousel-indicators>li {
    width: 100px;
    height: 85px;
}

#custCarousel .carousel-indicators li img {
    display: block;
    opacity: 0.5;
    padding: 2px;
    width: 100%;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, .125);
}

#custCarousel .carousel-indicators li.active img {
    opacity: 1;
    padding: 2px;
    border: 1.5px solid #004AAD;
}

#custCarousel .carousel-indicators li:hover img {
    opacity: 0.75
}

.carousel-item img {
    width: 100%
}

.side_img_div {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.side_img_div::-webkit-scrollbar {
    display: none;
}

.privacy_content p,
.privacy_content ul li {
    font-size: 20px;
    line-height: 35px;
    font-weight: 300;
}

.privacy_content h3 {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 300;
    padding-bottom: 7px;
}

.sticky-filter-course {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    overflow-y: scroll;
    overflow: hidden;
}

.carousel_text {
    text-align: start;
}

.event_carousel {
    width: 85%;
    margin: 0 auto;
}

.bakground_color {
    background-color: white;
}

.register_btn {
    padding: 1em;
    border: 1px solid #004AAD;
    width: 45%;
    text-align: center;
    color: white;
    background: #004AAD;
}

.register_btn:hover {
    border: 1px solid #004AAD;
    background-color: #004AAD !important;
    color: #fff !important;
    box-shadow: 0 4px 5px rgb(0 0 0 / 15%), 0 5px 5px rgb(0 0 0 / 10%);
}

.register_div {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.register_icon {
    margin: 0 0 0 1.5em;
    font-size: 1.5em;
}

/* mob responsive for navbar */
@media only screen and (max-width: 1024px) {
    .container-fluid {
        width: 97% !important;
    }
}

@media only screen and (max-width: 966px) {
    .container-fluid {
        width: 96% !important;
    }
}

@media only screen and (max-width: 725px) {
    .container-fluid {
        width: 95% !important;
    }
}

@media only screen and (max-width: 580px) {
    .container-fluid {
        width: 94% !important;
    }
}

@media only screen and (max-width: 483px) {
    .container-fluid {
        width: 93% !important;
    }
}

@media only screen and (max-width: 414px) {
    .container-fluid {
        width: 92% !important;
    }
}

@media only screen and (max-width: 362px) {
    .container-fluid {
        width: 91% !important;
    }
}

@media only screen and (max-width: 322px) {
    .container-fluid {
        width: 90% !important;
    }
}

@media only screen and (max-width: 319px) {
    .container-fluid {
        margin-top: 15% !important;
    }
}

/* mob responsive for navbar */

/* Event carousel */
.carousel_image_container {
    margin: 4em 0 0 0;
}

.virtual_div {
    margin: 0em 0 0 0 !important;
}

.upocoming_event_container {
    width: 95.5%;
    margin: 0 auto !important;
}

.carousel_row-mob {
    display: none;
}

@media only screen and (max-width: 1326px) {
    /* .carousel-control-next {
        padding-left: 2em !important;
    } */
}

@media only screen and (max-width: 1150px) {
    /* .carousel-control-next {
        padding-left: 3em !important;
    } */
}

@media only screen and (max-width: 1084px) {
    .date_time_container {
        font-size: 0.9em !important;
    }
}

@media only screen and (max-width: 989px) {
    .date_time_container {
        font-size: 0.8em !important;
    }
}

@media only screen and (max-width: 923px) {
    .date_time_container {
        font-size: 0.7em !important;
    }
}

@media only screen and (max-width: 1008px) {
    /* .carousel-control-next {
        padding-left: 4em !important;
    } */
}

@media only screen and (max-width: 1008px) {
    /* .carousel-control-next {
        padding-left: 5em !important;
    } */
}

@media only screen and (max-width: 1117px) {
    .register_btn {
        width: 65%;
    }
}

@media only screen and (max-width: 880px) {

    /* .slick-slide img {
        display: initial;
    } */
    .img_carousel_mob {
        width: 60% !important;
    }

    .carousel_row {
        display: none;
    }

    .carousel_row-mob {
        display: block;
    }

    .carousel_container {
        max-width: 100% !important;
    }

    /* .carousel-inner {
        height: 615px !important;
    } */

    .carousel-item img {
        width: 60%;
    }

    .carousel-inner img {
        height: 50vh;
        width: 100% !important;
    }

    .register_div {
        flex-direction: column;
    }

    .text_carousel_mob {
        padding: 2.5em;
    }

    .register_btn {
        width: 100%;
    }

    .register_icon {
        margin: 1em 0 0 1.5em;
    }

    .date_mob {
        margin: 0 !important;
    }

    /* .carousel-control-next-icon {
        display: none;
    } */

    .carousel-control-prev-icon {
        display: none;
    }

    .event_carousel {
        width: 100%;
        margin: 0;
    }

    /* .carousel-control-next {
        left: 89% !important;
    } */
}

@media only screen and (max-width:767px) {
    .mob_calander {
        margin: 0.4em 4.5em 0 0 !important;
    }
}

@media only screen and (max-width: 504px) {
    .text_carousel_mob {
        padding: 2.5em;
        /* padding-top: 1em; */
    }

    /* .carousel-inner {
        height: 700px !important;
    } */

    .carousel-item img {
        width: 80%;
    }
}

@media only screen and (max-width: 511px) {
    .img_carousel_mob {
        width: 93% !important;
    }

    .carousel-control-next {
        left: 88% !important;
    }
}

@media only screen and (max-width: 467px) {
    /* .carousel-control-next {
        left: 87% !important;
    } */

    .img_carousel_mob {
        width: 89% !important;
    }
}

@media only screen and (max-width: 441px) {
    /* .carousel-control-next {
        left: 86% !important;
    } */
}

@media only screen and (max-width: 418px) {
    /* .carousel-control-next {
        left: 85% !important;
    } */
}

@media only screen and (max-width: 397px) {
    .carousel_text_heading {
        font-size: 1.5em;
    }

    .f-18 {
        font-size: 15px !important;
    }

    /* .carousel-inner {
        height: 623px !important;
    } */
}

/* mob respnsive for Event carousel */

/* Event carousel */

/* blog details page styles */
.list_style {
    margin-left: 2em;
}

/* blog details page styles */

/* TechtalkEditer */
@media only screen and (max-width: 920px) {
    .direction_column {
        flex-direction: column !important;
    }

    .display_flex {
        display: flex;
    }

    .resource_list_image_mob {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .direction_column {
        flex-direction: row !important;
    }

    .display_flex {
        display: block;
    }

    .resource_list_image_mob {
        width: 50%;
        height: 20vh;
    }

}

/* TechtalkEditer */

/* TechtalkView */
@media only screen and (max-width: 1032px) {
    .note-video-clip {
        width: 96%;
    }
}

/* TechtalkView */

/* Register Modal */
.register_input {
    width: 100%;
    /* margin-top: 1em; */
    border-radius: 0.5em;
    border: 0.3px solid black;
    padding: 0.4em;
    outline: none;
}

.reg_label {
    padding: 1em 0 0 0;
}

.modal_register_btn {
    display: flex;
    padding: 0.5em;
    width: 35%;
    border-radius: 0.5em;
    outline: none;
    border: none;
    background-color: #004AAD;
    justify-content: center;
    align-items: center;
}

/* Register Modal */

.carousel_hole_container {
    background-color: white;
    margin: 5em auto;
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.carousel-control-prev {
    left: 6.5em;
}

.nb-4 {
    margin-bottom: 1.5rem;
}


.carousel-control-next {
    left: 90% !important;
    color: black;
}

@media only screen and (max-width: 880px) {
    .item.active {
        display: block;
        height: 61vh;
    }

    .carousel-control-next {
        display: none;
    }

}

@media only screen and (max-width: 414px) {
    .f-16 {
        font-size: 0.8em !important;
    }

    .mob_calander {
        margin: 0.4em 4.5em 0 0 !important;
        font-size: 0.8em;
    }
}

.visually-hidden {
    display: none;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {

    -webkit-filter: invert(100%);

            filter: invert(100%);
    font-size: 36px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon:hover {
    color: #004AAD !important;
}

.register_input:focus,
.register_input:focus:hover {
    border: 2px solid black !important;
    border-radius: 10px !important;
}


.container-fluid::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: 0 0;
}

.editorview:hover {
    cursor: pointer !important;
}

/* TechtalkEditer */

/* TechtalkView */
@media only screen and (max-width: 1032px) {
    .note-video-clip {
        width: 96%;
    }
}

/* TechtalkView */

/* Register Modal */
.register_input {
    width: 100%;
    margin-top: 1em;
    border-radius: 0.5em;
    border: 1px solid black;
    padding: 0.4em;
    outline: none;
}

.modal_register_btn {
    display: flex;
    padding: 0.5em;
    margin: 1em 0 1em 0;
    width: 35%;
    border-radius: 0.5em;
    outline: none;
    border: none;
    background-color: #004AAD;
}

/* .register_input_option{

} */
/* Register Modal */

.carousel_hole_container {
    margin: 5em auto;
    width: 85%;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.carousel-control-prev {
    left: 4em;
    color: black;
}

.carousel-control-next {
    left: 87% !important;
    color: black;
}

@media (max-width:990px) {
    .toggle-tabs {
        display: none;
    }
}

.fa-chevron-down:before {

    content: "" !important;
}

.rounded-pill:hover {
    cursor: pointer !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    color: #004AAD;
}

.rounded-pill:focus {
    color: #004AAD !important;
    border: 2px solid #004AAD;
    box-shadow: 0px 1px 1px #00000026;
}

.ec-sidebar {
    position: relative !important;
}


/* Techstyle by barath */

.tech-e {
    align-items: baseline;
    overflow: auto;
    /* height: auto; */
    /* height: 800px; */
    border: 2px green solid;

    /* position:sticky; */
}

#mydiv {
    height: 250px;
    width: 250px;
    overflow: auto;
}

#content {
    height: 800px;
    width: 250px;
    border: 2px green solid;
    background-color: #004AAD;
}


#fixed {
    overflow: auto !important;
}


/* Techtaks mobile responsive */

@media (max-width: 575.98px) {
    .container-fluid {
        width: 100% !important;
        line-height: 1.5;
        -webkit-line-clamp: 2 !important;
    }

    .f-18 {
        font-size: 18px !important;
    }

    .fw-600 {
        font-weight: 600 !important;

    }

    .market h1 {
        margin-top: 10% !important;
        padding: 3rem 0;
    }

    .text-dark {
        font-size: 16px !important;
        color: black !important;
    }

    @media (min-width: 575.98px) and (max-width: 991.98px) {
        .market h1 {
            margin-top: 5% !important;
            padding: 3rem 0;
        }

        .container-fluid {
            width: 100% !important;
            padding: 3rem 0 !important;
        }

        .market h1 {
            margin-top: 10% !important;
        }

    }




}

.list-active:active {
    color: #004AAD !important;
    border: 2px solid #004AAD !important;
    box-shadow: 0px 1px 1px #00000026;
    background-color: unset !important;
}



@media (min-width: 320px) and (max-width: 767px) {
    .market h1 {
        margin-top: 5% !important;
        padding: 3rem 0 !important;
    }

}

@media (min-width: 992px) {
    .market h1 {
        margin-top: 22px !important;
        padding: 3rem 0 !important;
    }

    .selete-w {
        width: 280px !important;
        margin: auto;
    }
}

/* MOBILE RESPONSIVE FOR THE RESOURSEVIEW    BY BARATH */

@media (max-width: 575.98px) {
    .f-16 {
        font-size: 16px !important;
        line-height: 1.5rem;
    }

    .resource_detail_page_title {
        font-size: 24px;
    }

    .broucher_view {
        margin: 0 !important;
    }

    .mt-5 {
        margin: 0% !important;

    }

    .share-mail {
        font-size: 20px !important;
    }

    .resource_detail_footer_nav {
        margin: 0 !important;
        padding: 0 !important;
    }




}

/* blog card */

.blog_date {
    margin: 0 0 0.5em 0 !important;
}

/* blog card */

/*   blog page editor choice mob responsive */

@media (min-width: 881px) and (max-width: 991px) {

    .ed_text {
        font-size: 1em !important;
    }

}

@media (min-width: 576px) and (max-width: 880px) {

    .resource_list_media {
        flex-direction: column !important;

    }

    .media-body {

        margin: 0.5em 0;
    }

    .ed_text {
        font-size: 1em !important;
    }

}


.top_M {
    margin-top: 2.8rem !important;
}

.fr-2 {
    font-size: 24px !important;
    font-weight: 400 !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .media-body p {
        font-weight: 400 !important;
    }
}

#download_brochure:hover {
    background-color: #e0ffe082 !important;
    color: #004AAD !important;
}

@media (min-width:360px) and (max-width:760px) {
    .carousel-inner img {
        height: auto;
        width: 100% !important;
    }

    .Eventdate {
        font-size: 0.8em;
    }

    .mob_calander {
        margin: 0.4em 4.5em 0 1.3em !important;
        font-size: 0.8em;
    }
}

@media (min-width:760px) and (max-width:850px) {
    .carousel-inner img {
        height: 24vh;
        width: 100% !important;
    }

}

.Caro-line-height {
    line-height: 1.5em;
}

.event-headline {
    font-size: 2.8em
}

.event-margin {
    margin-bottom: 0px !important;
}

.resource-width {
    width: 100% !important;
}

.techtalk-lineheight {
    line-height: 1.5em;
}

.Starcount {
    padding-left: 10px;
    font-size: 18px !important;
}

.event-button-wid {
    width: 100%;
}

._center {

    text-align: center;
    margin-top: 10%;
}


.error {
    margin-top: 50px;
}

.font-weight-bold {
    margin: 0 0 8px;
    font-weight: 700;

    font-size: 1.8em;
    line-height: 1.1;
    color: inherit;
}

.lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.4;
}

.error_link {
    color: #337ab7;
    text-decoration: none;
}

.error_img {
    width: 100px;
}

.avator {
    background-color: #004AAD;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

}

.avator>p {
    -webkit-transform: translate(0px, 25px);
            transform: translate(0px, 25px);
    font-size: 25px;
    color: #fff;
}

.Topscroll {
    bottom: 100px;
    color: #fff !important;
    display: block;
    font-size: 39px;
    height: 43px;
    line-height: 46px;
    position: fixed;
    right: 20px;
    text-align: center;
    text-decoration: none !important;
    
    width: 46px;
    z-index: 1000;
    border-radius: 3px;
    background-color: #3d4149;
    z-index: 1 !important;
    cursor: pointer;
}

.Topscroll:hover {
    color: #fff !important;
    height: 43px;
    width: 46px;
    background-color: #004AAD;
    z-index: 1 !important;
    cursor: pointer;
}

.marketmap_main_cat_12{
    margin-left: -8px;
   }
.market_Subcat_head_Row{
    padding: 9px 27px;
   }
.Cat_rows{
    padding: 10px 50px;
    margin-left: -65px;
}
.market_Division_head{
    background-color: #fff;
    padding: 5px 0px 5px 27px;
    margin-left: 15px;
    padding-left: 0px;
}

/* starting breadcrumb */




   
  /* -------------------------------- 
  
  Basic Style
  
  -------------------------------- */
  .cd-breadcrumb, .cd-multi-steps {
    width: 100%;
    padding: 0.5em 1em;
    
    background-color: #edeff0;
    border-radius: .25em;
  }
  .cd-breadcrumb::after, .cd-multi-steps::after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
  }
  .cd-breadcrumb li::after, .cd-multi-steps li::after {
    /* this is the separator between items */
    display: inline-block;
    content: '\00bb';
    margin: 0 .6em;
    color: #959fa5;
  }
  .cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
  }
  .cd-breadcrumb li > *, .cd-multi-steps li > * {
    /* single step */
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
  }
  .cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    /* selected step */
    color: #004AAD !important;
  }
  .no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
    /* steps already visited */
    color: #96c03d;
  }
  .cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    /* replace the default separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-separator.svg) no-repeat center center;
    vertical-align: middle;
    margin: 0px 7px;
  }
  .cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
    /* add a custom icon before each item */
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: .4em;
    margin-top: -2px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-icons-01.svg) no-repeat 0 0;
    vertical-align: middle;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
    /* change custom icon using image sprites */
    background-position: -20px 0;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
  }
  .cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
  }
  .cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
  }
  .cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
  }
  @media only screen and (min-width: 768px) {
    .cd-breadcrumb, .cd-multi-steps {
      padding: 0 2.4em;
    }
    .cd-breadcrumb li, .cd-multi-steps li {
      margin: 1.2em 0;
    }
    .cd-breadcrumb li::after, .cd-multi-steps li::after {
      margin: 0 1em;
    }
    .cd-breadcrumb li > *, .cd-multi-steps li > * {
      font-size: 16px;
    }
  }
  
.glossary_h1 {
    color: black;
  }
  .glossary_p_text {
    font-size: 17px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .albhabet {
    /* 
    margin-right: 3px; */
    padding-bottom: 6px;
    padding-top: 6px;
    text-align: center;
    color: black !important;
    width: 40px;
    cursor: pointer;
  
  }

  .albhabet span{
    color: black !important;
  }
  .albhabetactive {
    padding-top: 6px;
    text-align: center;
    padding-bottom: 6px;
    margin: 0px 3px;
    color: #ffffff!important;
    background: #004AAD !important;
    width: 40px;
    cursor: pointer;
  }
  .albhabetactive a {
    
    color: #ebebeb!important;
    
  }
  .albhabet:hover{
    background-color: #004AAD !important;
    color: white !important;
  }
  .albhabet:hover a{
    color: #fff!important;
  }
  .alphatext_bg {
    background-color: #edeff0;
    color: #004AAD;
  }
  .div_alp {
    padding-bottom: 20px;
  }
  .div_alp_top{
    padding-bottom: 20px;
    /* padding-top: 150px;  */
  }
  .heading_name {
    color: #004AAD;
  }
  .indi_content {
    font-size: 20px;
    /* opacity: 0.8; */
  }
  .glossary_partcular_btn {
    padding: 7px 5px !important;
    background: #004AAD !important;
    color: white;
    font-size: 14px !important;
    border-radius: 4px !important;
    border: 1px solid #004AAD !important;
  }
  .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 80px !important;
    padding: 20px 10px!important;
    z-index: 1020 !important;
    max-width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    background-color: white !important;
  }
  
  .scrollspy_example_top{
    padding-top: 300px;
  }
  
  @media only screen   
  and (min-device-width : 320px)   
  and (max-device-width : 600px)   {
    .sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 55px !important;
     
      padding: 20px 10px!important;

      z-index: 1020 !important;
      max-width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-around !important;
      background-color: white !important;
    } 
    }


    .glossary_alpha_stating_ltr {
        background-color: aqua;
        padding: 10px;
      }
      
      /* .glossary-glossary_number:hover{
      color: #004AAD;
      } */
      span.glossary-test:hover {
        color: #004AAD !important;
        /* text-decoration: underline; */
        border-bottom: 2px solid #004AAD;
      }

.text-grey{
color:  #666!important  ;
}
.modal-content{
    border-radius: 0.125rem;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    border: 0 solid rgba(0,0,0,.5)!important;
   
}

@media (min-width: 576px){
.modal-sm {
    max-width: 400px  !important;
}}
/* @media (min-width: 320px){
    .modal-sm {
        max-width: 300px  !important;
    }} */


.text-danger{
    color: #ff3547  !important;
  
}
/* .active{

        border: 1px solid #fff !important;
        display: inline-block !important;
        font-weight: 500 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
    } */
.modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }
    .modal-body {
        padding:1.5rem !important;
    }
    .modelbtn button ,.btn-model{
     background-color: #004AAD;
     color: #fff;
     border-radius:40px !important;
     padding: 4px 27px !important;

    }
    .modelbtn button:hover,.btn-model:hover {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc;
    }
    .waves-effect {
        position: relative;
        overflow: hidden;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
    .modelbtn button:focus ,.btn-model:focus{ 
        outline: 0 !important;
        box-shadow: none !important;

    }
     .contact_success_width {
        width: 400px !important;
    }
    .text-model{
        color: #004AAD !important;
        font-weight: 300 !important;
        margin-top: 18px;
    }
    .text-model> p{
        color: #212529;
    }
   .modelcontent{
       height: 100%;
       display: grid;
       align-items: center;
   }
   .modal.show .modal-dialog {
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
    display: grid;
    align-items: center;
    height: 80%;
}
.card.resource_card .card-img-certi-top {
    height: 11rem !important;
}

.author_cert_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.fixed_content_cert {
    /* height: 50px; */
    display: -webkit-box !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}
.f-15 {
    font-size: 15px !important;
}
.f-12 {
    font-size: 12px !important;
    text-align: left;
}
.star_cert{
    font-size: 20px;
    color: #004AAD ;
}

.fixed_content {
  /* height: 50px; */
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
.main-content{
    padding-top: 85px;
  }
.banner-sec{
    padding: 30px 0px ;
    position: relative;

}
.main-content .double-dots{
    position: absolute;
    right: 0;
    top: 0;
}
.bannar-main .banner-left{
    position: relative;
    z-index: 1;
}

.bannar-main h1 {
    color: #333333;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 43px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: 51px;
    top: 0;
     
}

.bannar-main p.para {
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
}
.bannar-main ul {
    list-style: none;
    margin: 0px;
    padding-bottom: 30px;
}

.bannar-main ul li {
    color: #333333;
    letter-spacing: 4px;
    font-size: 16px;
    font-weight: 500;
}
.bannar-main ul li a{
    color: #333333;
    letter-spacing: 4px;
    font-size: 16px;
    font-weight: 500;
}
.bannar-main ul li a:hover{
    color: #004AAD;
}
.bannar-main .button-sec a{
    padding: 10px 25px 10px 25px;
    display: inline-block;
    color: white;
    border-radius: 60px;
}
.bannar-main .button-sec .btn-1{
    background: #000000;
    margin-right: 10px;
}
.bannar-main .button-sec .btn-2{
    background: #004AAD;
}
.bannar-main .fill-round{
    position: absolute;
    width: 75px;
    height: 75px;
    background: #004AAD;
    border-radius: 50%;
    top: -11%;
    right: 18%;
}
.bannar-main .fill-round-line{
    position: absolute;
    width: 100px;
    height: 100px;   
    top: 55%;
    right: 0;
}
.right-banner {
    position: relative;
}
.right-banner .single-dot{
    position: absolute;
    top: 50%;
}
.right-banner .banner-images{
    position: relative;
    z-index: 5;
}


/* Responsive */

@media only screen and (max-width: 767px) {
    .banner-sec {
        padding: 55px 0px 20px;

      }
    .bannar-main h1 {
        font-size: 32px;
        line-height: 37px;
         
      }
      .bannar-main .fill-round {
         
        top: -17%;
        
      }
  }
.main-content-second-sec{
    background: #FBFBFB;
    padding: 30px 0px;
}

.main-content-second-sec .img-span{
    width: 65px;
    height: 65px;
    background: #004AAD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.what-do-sec h6{
    color: #333333;
    margin-bottom: 18px !important;
    font-weight: 600;
}
.what-do-sec p{
    color: #666666;
    margin-bottom: 18px !important;
    line-height: 26px;
    text-align: left;
}
.main-content-second-sec .view-more a{
    padding: 10px 25px 10px 25px;
    display: inline-block;
    color: white;
    border-radius: 60px !important;
}
.main-content-second-sec .view-more .btn1{
    background: #004AAD;
}
.main-content-second-sec .view-more{
   text-align: right;
   position: relative;
}
.main-content-second-sec .view-more .btn1::before{
    content: "";
    border-bottom: 1px solid #333;
    position: absolute;
    top: 24px;
    /* bottom: 0px; */
    margin: 0 auto;
    z-index: 99;
    left: 0px;
    right: 140px;
}

/* Responsive */

@media only screen and (max-width: 767px) {
    .main-content-second-sec h1{
       font-size: 32px;
       font-weight: 600;
    }
    .what-do-sec .col-md-3{
        text-align: center;
    }
    .main-content-second-sec {
        padding: 45px 0px 20px;
      }
      .main-content-second-sec .img-span {
     
        margin: 0 auto;
      }

      .what-do-sec p {
       
        text-align: center;
      }
    
  }
.main-content-customer-sec{
    padding: 50px 0px;
    background-image: url("/assets/img/homepage/customer_bg.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}
.main-content-customer-sec .contents .dark-color{
    font-weight: 600;
    color: #000000;
}
.main-content-customer-sec .contents h1{
    font-weight: 600;
    color: #FFFFFF;
}
.main-content-customer-sec .colum-5{
    width: 20%;
    padding: 0px 7px 7px 0px;
    min-width: 20%;
}
.main-content-customer-sec .card-box{
    width: 100%;
    background: white;
    height: 120px;
    padding-right: 5px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-content-customer-sec .card-box img{
    max-width: 100%;
    max-height: 100%;
}
.main-content-customer-sec .card-box-last{
    width: 100%;
    background: #004AAD;
    height: 160px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive */

@media only screen and (max-width: 767px) {
    .main-content-customer-sec .contents .dark-color {
       
        font-size: 32px;
      }
      .main-content-customer-sec .contents h1 {
       
        font-size: 32px;
      }
      .main-content-customer-sec .colum-5 {
        width: 50%;
        padding: 0px 7px 7px 0px;
        min-width: 50%;
      }
      .main-content-customer-sec .card-sec{
        padding: 20px 0px 10px 0px;
      }
    
  }

.main-content-marketplace-sec{
    padding: 50px 0px;
    background-image: url("/assets/img/homepage/marketplace/market_back.png");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
}
.main-content-marketplace-sec .double-dots{
    position: absolute;
    right: 0;
    top: 28%;
}
.main-content-marketplace-sec .contents h1{
    font-weight: 600;
}
.main-content-marketplace-sec .col-md-left {
  flex: 1 1;
}
.main-content-marketplace-sec .col-md-right {
    padding: 0px 15px 0px 100px;
    width: 535px;
}
.main-content-marketplace-sec .right-side .colum-div{
    width: 150px;
    padding: 10px;
}
.main-content-marketplace-sec .right-side .colum-div p{
    color: #333333;
    font-weight: 600;
}
.main-content-marketplace-sec .right-side .card-sec-market{
    width: 100%;
    background: #fff;
    height: 100px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}
.main-content-marketplace-sec .right-side .card-sec-market-last{
    width: 100%;
    background: #004AAD;
    height: 100px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}
.main-content-marketplace-sec .left-side h1{
    color: #004AAD;
    font-weight: 600;
}
.forms-sec {
   background-color: #2D3135;
   padding: 25px 25px 35px 25px;
   width: 100%;
   border-radius: 10px;
}
.forms-sec label{
    text-align: right;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}
.forms-sec input{
    padding: 4px 0px 4px 10px;
    width: 100%;
}
.forms-sec select{
    padding: 4px 0px 4px 10px;
    width: 100%;
    text-transform: capitalize;
}
.forms-sec .button-sec button{
    padding: 7px 20px 7px 19px;;
    display: inline-block;
    color: white;
    border-radius: 60px !important;
    background: #004AAD;
    border: 1px solid #999999;
    cursor: pointer;
    text-transform: capitalize !important;
}
.forms-sec .button-name-text-trans{
    text-transform: capitalize !important;
}
.input-ul-sec {
    position: relative;
}
.dropcatagory {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 5;
    height: auto;
    overflow: scroll;
}

/* Responsive */

@media only screen and (max-width: 767px) {
    .main-content-marketplace-sec .contents h1 {
        font-weight: 600;
        font-size: 32px;
      }
      .main-content-marketplace-sec .col-md-right {
        padding: 15px 15px 0px 15px;
        width: 100%;
        z-index: 1;
      }
      .main-content-marketplace-sec .right-side .colum-div {
        width: 50%;
        padding: 10px;
      }
      .right-side{
        justify-content: center;
      }
      
      .card-sec-market-last span{
        display: inline !important;
      }
      .card-sec-market-last{
        margin-bottom: 15px;
      }
      .main-content-marketplace-sec .left-side h1 {
        font-size: 32px;
        margin-bottom: 20px !important;
      }
      .main-content-marketplace-sec .mobile_version {
        
      }
      .forms-sec label {
        text-align: left;
      }
    
  }
.main-content-feature-product-sec{
    padding: 50px 0px;
    background-color: #F6F6F6;
    width: 100%;
     
    position: relative;
}
.main-content-feature-product-sec .contents h1{
    font-weight: 600;
}

.main-content-feature-product-sec .card-sec .card{
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    margin-bottom: 20px;
}
.main-content-feature-product-sec .card-sec .card img{
    width: 120px;
}
.main-content-feature-product-sec .card-sec p{
    font-size: 12px;
    color: #666666;
    line-height: 23px;
    -webkit-line-clamp: 5 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.main-content-feature-product-sec .card-sec .card{
    height: 245px;
}

/* images */
.feature-img.mb-4 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}


/* Responsive */

@media only screen and (max-width: 767px) {
    .main-content-feature-product-sec .contents h1 {
        font-weight: 600;
        font-size: 32px;
      }
      .main-content-feature-product-sec .card-sec{
        padding: 20px 0px;
      }
    
  }

.main-content-resource-sec{
    padding: 50px 0px;
    background-image: url("/assets/img/homepage/resource/resource_back.png");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
   
}
.main-content-resource-sec .contents h1{
    font-weight: 600;
    color: #fff;
}

.main-content-resource-sec .card-sec .card{
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    margin-bottom: 20px;
    height: 335px;
}
.main-content-resource-sec .card-sec .main-content-resource{
    align-items: center;
}
.main-content-resource-sec .card-sec .main-content-resource .resource-left-side{
    flex: 1 1;
}
.main-content-resource-sec .card-sec .main-content-resource .resource-left-side .feature-img{
    width: 100px;
    margin-right: 0px;
}
 
.main-content-resource-sec .card-sec h4{
    font-weight: 600;
    color: #333333;
    font-size: 20px;
    margin-bottom: 15px !important;
    -webkit-line-clamp: 4 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.main-content-resource-sec .card-sec p{
    color: #666666;
    line-height: 22px;
    font-size: 15px;
    -webkit-line-clamp: 4 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* button section */
.main-content-resource-sec .btn-sec a {
    padding: 10px 40px 10px 40px;;
    display: inline-block;
    color: white;
    border-radius: 60px;
}
.main-content-resource-sec .btn-sec .btn-1{
    background: #000000;
    margin-right: 10px;
}
/* button section end */


/* Responsive */

@media only screen and (max-width: 767px) {
    .main-content-resource-sec .contents h1 {
        font-weight: 600;
        color: #fff;
        font-size: 32px;
      }
    .main-content-resource-sec .card-sec {
        padding: 20px 0px;
      }
    
  }

.main-content-event-sec{
    padding: 50px 0px;
    /* background-image: url("/assets/img/homepage/event/event_back.png"); */
    background-color: #F6F6F6;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
   
}
.main-content-event-sec .contents h1{
    font-weight: 600;
    color: #333333;
}
.main-content-event-sec .card-sec .img-sec .img-1{
   position: relative;
}
.main-content-event-sec .card-sec .img-sec .img-1 .card-date{
    position: absolute;
    left: 275px;
    top: 80px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 65px;
    text-align: center;
}
.main-content-event-sec .card-sec .img-sec .img-1 .card-date h4{
    font-size: 22px;
    font-weight: 600;
}
.main-content-event-sec .card-sec .img-sec .img-1 .card-date span{
    font-size: 13px;
    font-weight: 300;
    color: #888888;
    display: block;
}


.main-content-event-sec .card-sec p{
    color: #666666;
    line-height: 22px;
    font-size: 15px;
    -webkit-line-clamp: 2 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.main-content-event-sec .card-sec h4{
    color: #333333;
    font-weight: 600;
    -webkit-line-clamp: 2 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
     
}

/* button section */
.main-content-event-sec .btn-sec a {
    padding: 10px 40px 10px 40px;;
    display: inline-block;
    color: white;
    border-radius: 60px;
}
.main-content-event-sec .btn-sec .btn-1{
    background: #004AAD;
    margin-right: 10px;
}
/* button section end */


/* Responsive */
@media only screen and (max-width: 767px) {
    .main-content-event-sec .contents h1 {
        font-weight: 600;
        color: #333333;
        font-size: 32px;
      }
      .main-content-event-sec .card-sec{
        padding: 20px 0px;
      }
    
  }
/* blog style */
.user .user-img {
  width: 150px;
    border: 10px solid #0d6efd;
    border-radius: 50%;
  }
  
  .user-img img {
    width: 100%;
    border-radius: 50%;
  }
  
  .user {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .user .user-detail {
    background: #bdbdbd;
    border-radius: 50%;
    padding: 12px;
    position: absolute;
    bottom: -15px;
    right: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
}
  
  .user-detail p {
    margin-bottom: 0;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
  }

  .rightblog {
    padding-left: 20px;
}
  
  .user-ai h3 {
    font-size: 21px;
    font-weight: 600;
    color: #6c6c6c;
    margin-bottom: 8px !important;
}
  
.user-ai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-bottom: 25px;
}

.hm_news_wrapper {
    padding: 50px 0px;
}
  
  .user-ai p {
    margin-top: 0;
    color: #acabab;
    margin-bottom: 6px !important;
    font-weight: 400;
    line-height: 23px;
}
  
  .user-ai a {
    color:#004AAD;
    text-decoration: none;
    font-weight: 600;
  }

  .leftblog.position-relative {
    width: 290px;
}
  
  .container .underline {
    position: relative;
    z-index: 1;
  }
  
  .container .underline::after {
    content: "";
    height: 1px;
    width: 90%;
    position: absolute;
    background: #b7b5b5;
    z-index: -2;
    bottom: 6px;
    right: 0;
  }
  /* blog style end */

  /* view more */
  .hm_news_wrapper .view-more a{
    padding: 10px 25px 10px 25px;
    display: inline-block;
    color: white;
    border-radius: 60px !important;
}
  .hm_news_wrapper .view-more .btn1{
    background: #004AAD;
}
.hm_news_wrapper .view-more{
   text-align: right;
   position: relative;
}
.hm_news_wrapper .view-more .btn1::before{
    content: "";
    border-bottom: 1px solid #333;
    position: absolute;
    top: 24px;
    /* bottom: 0px; */
    margin: 0 auto;
    z-index: 99;
    left: 0px;
    right: 140px;
}

/* Responsive */
@media only screen and (max-width: 767px) {
.hm_news_wrapper .contents  h1{
  font-weight: 600;
  font-size: 32px;
}
  
}
